const React = require("react")
const Support = require("./src/utils/support")
const MobileTestContext = Support.MobileTestContext
const mobileTest = Support.mobileTest
const safariTest = Support.safariTest

require("katex/dist/katex.min.css")

export const wrapRootElement = ({ element }) => {
  return (
    <MobileTestContext.Provider value={{ isMobile: mobileTest(), isSafari: safariTest() }}>
      {element}
    </MobileTestContext.Provider>
  )
}

export const onServiceWorkerUpdateReady = () => window.location.reload()
