import { useState, useEffect, createContext } from "react"

export const MobileTestContext = createContext({ isMobile: false, isSafari: false })

export const mobileTest = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Firefox/i.test(navigator.userAgent)

export const safariTest = () =>
  !!navigator.userAgent.match(/Safari/i) && !navigator.userAgent.match(/Chrome/i)

export const glSupport = ctx =>
  ctx instanceof WebGL2RenderingContext || ctx instanceof WebGLRenderingContext

export const gl2Support = ctx => ctx instanceof WebGL2RenderingContext

export function useDeviceDetection() {
  const [isMobile, set] = useState(false)
  useEffect(() => set(mobileTest()), [])
  return isMobile
}

export function getRenderingContext(canvas, ctxType = "2d", contextAttributes = {}) {
  let ctx
  if (window.WebGL2RenderingContext && ctxType === "webgl2") {
    ctx = canvas.getContext("webgl2", contextAttributes)
    if (ctx) return ctx
  }
  if (window.WebGLRenderingContext && ctxType === "webgl") {
    ctx = canvas.getContext("webgl", contextAttributes)
    if (ctx) return ctx
  }
  return canvas.getContext("2d", contextAttributes)
}
